import { useState } from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/images/Qompas-logo.png";
import NotificationModal from "../modal/modal";
function Navigation() {
  const [modalNotify, setModalNotify] = useState(false);

  return (
    <>
      <NotificationModal
        show={modalNotify}
        onHide={() => setModalNotify(false)}
      />

      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <Image src={logo} width={100} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="#home" className="mx-2">
                Home
              </Nav.Link>
              <Nav.Link href="#about" className="mx-2">
                About Us
              </Nav.Link>
              <Nav.Link href="#features" className="mx-2">
                Features
              </Nav.Link>
              <Nav.Link href="#team" className="mx-2">
                Team
              </Nav.Link>

              <Nav.Link href="#contact" className="mx-2">
                Contact Us
              </Nav.Link>
            </Nav>

            <Nav>
              <Button
                variant="light"
                className="shadow rounded border border-danger px-4 py-2"
                onClick={() => setModalNotify(true)}
              >
                Get Started
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
