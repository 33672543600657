import { Card, Col, Container, Image, Row } from "react-bootstrap";

function Features() {
  const features = [
    {
      id: 1,
      icon: "/features-icon.png",
      title: "Wallet",
      details:
        "Deposit and withdrawals are made from the Wallet, money locked in the wallet yields interest for lock period. This feature is available in multiple currencies.",
    },
    {
      id: 2,
      icon: "/features-icon.png",
      title: "Real Estate",
      details:
        "This feature involves backers investing in real estate projects, such as property developments or renovations.",
    },
    {
      id: 3,
      icon: "/features-icon.png",
      title: "Creative",
      details:
        "Creative projects, such as films, music albums, books, and art installations, Models, can be funded through crowdfunding campaigns.",
    },

    {
      id: 4,
      icon: "/features-icon.png",
      title: "Agriculture",
      details:
        "This feature involves backers investing in agricultural projects and exports.",
    },
    {
      id: 5,
      icon: "/features-icon.png",
      title: "Sports",
      details:
        "This feature involves backers investing in careers of young rising stars in athletics and football.",
    },

    {
      id: 6,
      icon: "/features-icon.png",
      title: "Student Loan",
      details:
        "This feature involves backers investing in school fees payment for students to receive interests.",
    },
  ];
  return (
    <div id="features">
      <div className="features-section p-5 my-5">
        <Container>
          <div className="text-white text-center my-4">
            <h3>Features</h3>
            <p>
              dynamic ecosystem that fosters collaboration, innovation, and
              community engagement.
            </p>
          </div>
          <Row>
            {features.map((feature, index) => (
              <Col md={4} key={index} className="">
                <Card className="my-4 custom-main">
                  <Card.Body>
                    <div className="my-4">
                      {" "}
                      <Image src={feature.icon} width={50} />{" "}
                    </div>
                    <h4 className="my-3"> {feature.title} </h4>
                    <Card.Text>{feature.details}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Features;
