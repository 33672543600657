import { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import phoneImg from "../../assets/images/phone-image.png";
import NotificationModal from "../modal/modal";

function Hero(props) {
  const [modalNotify, setModalNotify] = useState(false);

  return (
    <div id="home">
      <NotificationModal
        show={modalNotify}
        onHide={() => setModalNotify(false)}
      />
      <div className="hero-section">
        <Container>
          <Row>
            <Col md={6}>
              <div className="pt-5">
                <h6 className="fw-bold display-2 mt-5">
                  Cutting-edge <br /> Crowdfunding{" "}
                </h6>
                <p className="my-5">
                  Our platform is designed to overcome the drawbacks of
                  conventional fundraising techniques and take advantage of
                  technological advancements to produce a seamless, effective,
                  and enjoyable crowdfunding experience.
                </p>{" "}
                <div className="">
                  <Button
                    className="shadow-lg px-5 py-3   bg-m  btn-hover rounded border-0 "
                    onClick={() => setModalNotify(true)}
                  >
                    Get Started
                  </Button>
                  <Button
                    onClick={() => setModalNotify(true)}
                    className="shadow-lg px-5 py-3 mx-4  bg-transparent text-black  btn-hover rounded border-1 border-dark"
                  >
                    Login
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-center d-sm-none d-none d-md-block">
                <Image src={phoneImg} width="70%" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Hero;
