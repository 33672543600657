import { Modal } from "react-bootstrap";

function NotificationModal(props) {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Notice !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Stay tuned, because something extraordinary is just around the corner
          !
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}
export default NotificationModal;
