import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/images/Qompas-logo.png";
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsFacebook, BsFillPhoneFill, BsInstagram, BsPinMapFill } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";

function Footer() {
  return (
    <footer id="contact">
      <div className="p-5 footer-section mt-5">
        <Container>
          <Row>
            <Col md={4}>
              <div className=" ">
                <Image src={logo} alt="Logo" />
                <p className="mt-3">
                  <small>
                    Our crowdfunding platform is a dynamic ecosystem that
                    fosters collaboration, innovation, and community engagement.
                    By leveraging cutting-edge technology and user-centric
                    design, we are reimagining the crowdfunding landscape to
                    empower creators and backers like never before.
                  </small>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="my-5">
                <h5 className="">Contact Us</h5>
                <p className="my-4 ">
                  <BsPinMapFill className="circle-icon bg-m text-black" />
                  <span className="text-decoration-none ms-3 ">
                    <small>
                    26 SA Agada , Dawaki, FCT Abuja
                    
                    </small>
                  </span>
                </p>

                <p className="my-4">
                  <BiMailSend className="circle-icon bg-m text-black" />
                  <Link
                    href="mailto:Qompasvest@gmail.com"
                    className="text-decoration-none ms-3 "
                  >
                    <small className="">Qompasvest@gmail.com</small>
                  </Link>
                </p>
                <p className="my-4">
                  <BsFillPhoneFill className="circle-icon bg-m text-black" />
                  <Link
                    href="tel:07038710878"
                    className="text-decoration-none ms-3 "
                  >
                    <small className="">07038710878</small>
                  </Link>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="pt-3 mb-5 text-center">
                <h4 className="mt-4  mt-5">Social Links</h4>
                <div className="mt-4">
                  <span className="my-4 ">
                    <Link to="https://www.facebook.com/gnxtltd?mibextid=LQQJ4d" className="social-icon">
                      <BsFacebook className="h2 text-c" />
                    </Link>
                  </span>
                  <span className="my-4">
                    <Link href="#" className="social-icon">
                      <AiFillTwitterCircle className="h2 text-c" />
                    </Link>
                  </span>

                  <span className="my-4 social-icons">
                    <Link to="https://instagram.com/qompas_hq?igshid=MzRlODBiNWFlZA==" className="social-icon ">
                      <BsInstagram className="h2 text-c" />
                    </Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white p-1 text-center">
        <small>
          Copyright © {new Date().getFullYear()} Qompas. All Rights Reserved
        </small>
      </div>
    </footer>
  );
}
export default Footer;
