import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Navigation from "./components/navbar/navbar";
import Hero from "./components/hero/hero";
import About from "./components/about/about";
import Features from "./components/features/features";
import TeamMembers from "./components/team/team";
import Action from "./components/action/action";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <About />
      <Features />
      <TeamMembers />
      <Action />
      <Footer />
    </div>
  );
}

export default App;
