import { Card, Col, Container, Row } from "react-bootstrap";

function TeamMembers() {
  const teams = [
    {
      id: 1,
      image: "/team/Shola.png",
      name: "Shola Julius",
      jobTile: "Founder",
    },
    {
      id: 2,
      image: "/team/david.png",
      name: "David Imoni",
      jobTile: "IT Consultant",
    },
    {
      id: 3,
      image: "/team/Remi.png",
      name: "Remi Taylor",
      jobTile: "Director",
    },
    {
      id: 4,
      image: "/team/Olajide.png",
      name: "Olajide Ajibowo",
      jobTile: "Director",
    },
    {
      id: 5,
      image: "/team/Ori.png",
      name: "Ori Agu",
      jobTile: "Director",
    },
  ];
  return (
    <div id="team">
      <div className="my-5 py-5">
        <Container>
          <div className="my-4">
            <h4>Meet our Team Members</h4>
          </div>
          <Row>
            {teams.map((team, index) => (
              <Col md={4} key={index}>
                <Card className="my-4 border-0">
                  <Card.Img src={team.image} variant="top" />
                  <Card.Body className="text-center">
                    <Card.Title> {team.name} </Card.Title>
                    <Card.Text>{team.jobTile}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default TeamMembers;
