import { Col, Container, Image, Row } from "react-bootstrap";
import aboutImg from "../../assets/images/about-side-img.png";

function About() {
  return (
    <div id="about">
      <Container className="my-5">
        <Row>
          <Col md={6}>
            <div className="mt-5">
              <Image src={aboutImg} className="img-fluid" />
            </div>
          </Col>
          <Col md={6}>
            <div className="p-4 border border-bottom-2 border-danger  rounded ">
              <h4 className="pt-3 mt-5">ABOUT US</h4>
              <p className="my-4">
                Qompas is a cutting-edge crowdfunding platform created to
                transform how people, businesses, and organisations generate
                money for their projects, ventures, and creative activities. Our
                platform is designed to overcome the drawbacks of conventional
                fundraising techniques and take advantage of technological
                advancements to produce a seamless, effective, and enjoyable
                crowdfunding experience.
              </p>
              <p className="">
                Our crowdfunding platform is a dynamic online ecosystem that
                brings together a large community of backers and supporters with
                project creators. Creators may share their projects, set
                financing objectives, and interact with backers through an easy
                and user-friendly interface, while backers can choose and
                support a variety of projects that align with their interests
                and values.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default About;
