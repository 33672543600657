import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NotificationModal from "../modal/modal";

function Action() {
  const [modalNotify, setModalNotify] = useState(false);
  return (
    <div id="action">
      <NotificationModal
        show={modalNotify}
        onHide={() => setModalNotify(false)}
      />
      <div className="action-section">
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div className="text-center pt-5 my-5">
                <h4 className="my-5">
                  Due diligence, review and ratings of projects
                </h4>
                <p className="">
                  Our innovative platform aims to bridge the gap between
                  creators seeking financial support and a global network of
                  backers who are passionate about bringing inspiring ideas to
                  life.
                </p>
                <Button
                  onClick={() => setModalNotify(true)}
                  className="shadow-lg px-5 py-3 mt-2  bg-m  btn-hover rounded border-0"
                >
                  Get Started
                </Button>
                <Button
                  onClick={() => setModalNotify(true)}
                  className="shadow-lg px-5 py-3 mt-2 ms-4  bg-transparent text-white   btn-hover rounded border-1 border-light"
                >
                  Login
                </Button>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Action;
